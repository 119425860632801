import { Form } from "../../containers/Form/sca";
import { useState } from "react";
import { Row } from "reactstrap";
import LogoImage from '../../template/svg/GoDoLogo2.png'
import { Container, Logo, LogoContainer, MainContainer } from "./styled";
import Flags from './flagscsa';
import lockImage from '../../template/images/Lock.png';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import PopAlert from "./PopAlert";
import { useTranslation, Trans } from 'react-i18next';
import LanguageTranslation from "./LanguageTranslation";

function SCA() {
  const [flagData, setFlagData] = useState('FSC');
  const [show, setShow] = useState(false);
  const [backPage, setBackPage] = useState();
  const [entityName, setEntityName] = useState('');

  const { t, i18n } = useTranslation();

  // Function to update flag data
  const updateFlagData = (newText) => {
    setFlagData(newText);
  };

  return (
    <MainContainer>
      <Row className="header" style={{ padding: "20px 0px", margin: "0" }}>
        <LogoContainer>
          <a href="https://www.godocm.com/"><Logo src={LogoImage} className="logoCss" /></a>
          <div className="d-flex align-items-center">
            <LanguageTranslation className='d-sm-block d-none' />
            <Flags updateFlagData={updateFlagData} entityName={setEntityName} entityUpdate={setBackPage} popUp={setShow} />
          </div>
        </LogoContainer>
        <LanguageTranslation className='w-100 d-block d-sm-none' />
      </Row>
      <Row>
        <PopAlert show={show} entity={setBackPage} entityName={entityName} entityChangeValue={flagData} popUp={setShow} />
      </Row>
      <Row className="top_security w-100">
        <p style={{ marginBottom: '0px', fontSize: '16px' }} className={`top_sec_p d-flex align-items-center justify-content-center ${i18n.language === 'de' ? 'flex-row-reverse' : ''}`}>
          <img
            src={lockImage}
            style={{ marginTop: '-3px', width: '20px', marginRight: i18n.language !== 'de' ? '15px' : 0, marginLeft: i18n.language === 'de' ? '15px' : 0 }}
            alt="Lock Icon"
          />
          {t('description.part1')}
        </p>
      </Row>
      <Row className="mt_50 w-100" style={{fontSize: `${i18n.language === 'de' ? '18px' : ''}`, margin: "50px 0", marginBottom: "0px"}}>
        <h1 className={`text-center mb-4 ${i18n.language === 'de' ? 'fs-3' : 'fs-5'}`}>{t('description.part2')}</h1>
        {flagData === 'FSA' ?
          <p className={`headingBlock ${i18n.language === 'de' ? 'text-end' : ''}`} style={{ direction: `${i18n.language === 'de' ? 'rtl' : ''}`}}>
            <Trans i18nKey="description.partBack">
              <b>"GODO Capital Limited”</b>
              <b>Saint Vincent and the Grenadines.</b>
              <b>Financial Services Authority (FSA)</b>
            </Trans>
          </p> 
          : flagData === 'SCA' ?
            <p className={`headingBlock ${i18n.language === 'de' ? 'text-end' : ''}`} style={{ direction: `${i18n.language === 'de' ? 'rtl' : ''}`}}>
              <Trans i18nKey="description.part83">
              <b>"GODO Introduction Financial Services L.L.C."</b>
              <b>Securities Commodities Authorities (SCA) in the United Arab Emirates</b>
              <b>GODO Limited (Mauritius)</b>
              <b>exclusively</b>
              <b>UAE Nationals and Residents</b>
              </Trans>
            </p> :
            <p className={`headingBlock ${i18n.language === 'de' ? 'text-end' : ''}`} style={{ direction: `${i18n.language === 'de' ? 'rtl' : ''}`}}>
              <Trans i18nKey="description.part82">
              <b>"GODO LTD”</b> 
              <b>Mauritius</b>
              <b>Financial Service Commission (FSC)</b>
              </Trans>
            </p>
        }
      </Row>
      <Row className="text-center w-100">
        <h1 className="secondary">{t('description.part4')}</h1>
        <p className={`primary fw-bold ${i18n.language === 'de' ? 'fs-5' : 'fs-6'} `}>{t('description.part5')}</p>
      </Row>
      <Container>
        <Form entity={flagData} mainPage={backPage} updateFlag={updateFlagData} />
      </Container>
      <div className={`footer ${i18n.language === 'de' ? 'fs-5' : ''}`}>
        <div>
          <p className="text-center">{t('description.part30')}</p>
          <p style={{ direction: i18n.language === 'de' && 'rtl'}} className={`${i18n.language === 'de' ? 'text-end' : ''}`}>{t('description.part31')}</p>
        </div>
      </div>
      <ToastContainer />
    </MainContainer>
  );
}

export default SCA;