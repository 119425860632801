import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          description: {
            part1: 'This is a secure online registration',
            part2: 'Unlock the World of Opportunities: Begin Your Trading Journey Today!',
            partBack: '<0>"GODO Capital Limited”</0> (FSA License No 26005) is an authorized and registered limited company under the laws of <1>Sain Vincent and the Grenadines. </1> We ensure that your account will be managed in full compliance with the rules and regulations set forth by the <2>Financial Services Authority (FSA)</2>."',
            part4: 'Application Form',
            part5: "You're Just a Step Away from Achieving Your Financial Goals... Dive In Now!",
            part6: 'Basic Information',
            part7: 'Personal and Financial',
            part8: 'Trading Information', 
            part9: 'Declaration',
            part10: 'Full Name ( as per your passport or national ID )',
            part11: 'Email Address',
            part12: 'Nationality',
            part13: '*Please enter your name as per your passport or national ID',
            part14: 'Platform',
            part15: 'Account Type',
            part16: 'Password',
            part17: 'Confirm Password',
            part18: 'Country Of Residence',
            part19: 'Phone No',
            part20: 'Password Should',
            part21: 'At least 1 lowercase letter',
            part22: '1 capital (uppercase) letter',
            part23: 'At least 1 Number',
            part24: 'Minimum 8 characters', 
            part25: 'Maximum 12 characters',
            part26: '1 Special characters ($,#,@,&)',
            part27: 'The provided information is correct, and I authorize GoDo to use the information to assess the level of appropriateness of the provided services and products.',
            part28: 'Next',
            part29: "<0>Exercise caution:</0> If uncertain about trading risks or uncomfortable with leveraging, it's advisable not to proceed with the application form, as trading entails the potential loss of your entire investment.",
            part30: 'For assistance, please contact our Client Service Team at cs@godocm.com',
            part31: '*Risk Warning: Trading in Forex/ CFDs and Other Derivatives is highly speculative and carries a high level of risk. It is possible to lose all your capital. These products may not be suitable for everyone and you should ensure that you understand the risks involved. Seek independent advice if necessary. Speculate only with funds that you can afford to lose. The information on this site is not directed at residents of the United States and is not intended for distribution to, or use by, any person in any country or jurisdiction where such distribution or use would be contrary to local law or regulation. The domain www.godocm.com is operated by Godo Ltd, which is authorized and regulated by the Financial Service Commission ( FSC ) in Mauritius (License No GB20025812).',
            part32: 'Personal Information',
            part33: 'Country Of Residence',
            part34: 'Current / Permanent Address',
            part35: 'ID Type',
            part36: 'ID Number',
            part37: 'Upload Document (Proof Of Identity-Front)',
            part38: 'Upload Document (Proof Of Identity-Back)',
            part39: 'Upload Document (Proof Of Address)',
            part40: 'Date of Birth ( as per the given ID )',
            part41: 'Year',
            part42: 'Month',
            part43: 'Day',
            part44: 'Financial Information',
            part45: 'Annual Income',
            part46: 'Net Worth',
            part47: 'Preferable mode of Payment',
            part48: 'Source of Income',
            part49: 'Upload Document (Proof Of Income)',
            part50: 'Supplementary Document (If any)',
            part51: 'Previous',
            part52: 'Have you experienced trading before?',
            part53: 'Yes',
            part54: 'No',
            part55: 'I want to learn, and I have understood all risks associated with this trading platform.',
            part56: 'Will this account be traded by anyone other than you?',
            part57: 'I accept electronic trading terms',
            part58: 'Are you a US Citizen and subject to tax?',
            part59: 'Politically Exposed Person (PEP):',
            part60: 'Are you a PEP?',
            part61: 'Declaration:',
            part62: 'Prior to submitting this application form, it is essential that you have read and understood the following terms and conditions that will govern your trading account with GODO LTD (“GoDo”), and which together with this application form, comprise our ‘Client Agreement’ with you.',
            part63: 'I hereby declare the following:',
            part64: 'I agree that I have access to all information and duly understood:',
            part65: 'Terms & Conditions;',
            part66: 'Complaint Policy;',
            part67: 'Conflicts Of Interest Policy;',
            part68: 'Cookies Policy;',
            part69: 'Credit Card Disclaimer;',
            part70: 'Execution Policy;',
            part71: 'Privacy Policy;',
            part72: 'Withdrawal & Refund;',
            part73: 'Risk Warning',
            part74: 'I understand the risk of margin trading and aware that a significant loss greater than the initially deposit account can be incurred',
            part75: 'I confirm that all the information provided is correct and in case of any change I am responsible to notify GoDo in written.',
            part76: 'I hereby signed my agreement with GODO LTD and agree that my trading account is subject to the regulations under Financial Services Authority (FSA), Saint Vincent.',
            part77: 'Submit',
            part78: 'What products you have traded in?',
            part79: 'Frequency',
            part80: 'Please attach Power of Attorney',
            part81: 'TIN',
            part82: '<0>"GODO LTD”</0> (FSC License No GB20025812) is an authorized and registered limited company under the laws of <1>Mauritius</1>. We ensure that your account will be managed in full compliance with the rules and regulations set forth by the <2>Financial Service Commission (FSC)</2>.',
            part83: '<0>"GODO Introduction Financial Services L.L.C."</0> (SCA License no: 20200000168) is an authorized and registered limited liability company under the law of the <1>Securities Commodities Authorities (SCA), United Arab Emirates</1>, under its exclusive partnership with <2>GODO Limited (Mauritius)</2> to <3>exclusively</3> facilitate our <4>UAE Nationals and Residents</4>. However, GODO Introduction is not authorized to hold clients assets or money and all services are provided only on introduction basis.',
            part84: 'I hereby signed my agreement with GODO LTD and agree that my trading account is subject to the regulations under Financial Services Commission (FSC), Mauritius.',
            part85: 'I hereby signed my agreement with GODO LTD and agree that my trading account is subject to the regulations under Securities Commodities Authority (SCA), UAE.',
            part86: '',
            part87: '',
            part88: '',
            part89: '',
            part90: '',
          }
        }
      },
      de: {
        translation: {
          description: {
            part1: 'هذا التسجيل أمن عبر الأنترنت',
            part2: 'افتح عالم من الفرص: ابدأ رحلتك في التداول اليوم',
            part3: ' هي شركة مساهمة محدودة مرخصة ومسجلة وفقًا لقوانين سانت فنسنت وجرينادينز. نضمن إدارة حسابك بالكامل وفقًا للقواعد واللوائح التي  \n (رقم ترخيص هيئة الخدمات المالية 26005) <2>"GoDo Capital Limited"</2> شركة',
            partBack: '<0>"GoDo Capital Limited"</0> (رقم ترخيص هيئة الخدمات المالية 26005) \n <1> هي شركة مساهمة محدودة مرخصة ومسجلة وفقًا لقوانين سانت فنسنت وجرينادينز. نضمن إدارة حسابك بالكامل وفقًا للقواعد واللوائح التي <1/> وضعتها هيئة الخدمات المالية \n (FSA)',
            part3_2: ' وضعتها هيئة الخدمات المالية \n (FSA)',
            part4: 'نموذج الطلب',
            part5: 'أنت على بُعد خطوة واحدة من تحقيق أهدافك المالية... ابدأ الآن',
            part6: 'المعلومات الأساسية',
            part7: 'الشخصية والمالية',
            part8: 'معلومات التداول',
            part9: 'إقرار',
            part10: 'الاسم الكامل (كما يظهر في جواز السفر أو البطاقة القومية)',
            part11: 'البريد الإلكتروني',
            part12: 'الجنسية',
            part13: 'برجاء إدخال الاسم كما يظهر في جواز السفر أو البطاقة القومية*',
            part14: 'المنصة',
            part15: 'نوع الحساب',
            part16: 'كلمة المرو',
            part17: 'تأكيد كلمة المرور',
            part18: 'بلد الإقامة',
            part19: 'رقم الهاتف',
            part20: 'كلمة السر يجب أن تحتوي على',
            part21: 'على الأقل حرف واحد صغير',
            part22: 'حرف واحد كبير على الأقل',
            part23: 'رقم واحد على الأقل',
            part24: '8 أحرف على الأقل',
            part25: '12 حرفًا كحد أقصى',
            part26: 'حرف خاص واحد (مثل $، #، @، &)',
            part27: 'المعلومات المقدمة صحيحة، وأفوض GoDo باستخدام هذه المعلومات لتقييم مدى ملاءمة الخدمات والمنتجات المقدمة',
            part28: 'التالي',
            part29: 'توخَّ الحذر: إذا كنت غير متأكد من مخاطر التداول أو غير مرتاح لاستخدام الرافعة المالية، فمن المستحسن عدم المضي قدمًا في ملء نموذج الطلب، لأن التداول ينطوي على احتمال فقدان كامل استثمارك',
            part30: 'cs@godocm.com للحصول على المساعدة، يرجى التواصل مع فريق خدمة العملاء لدينا على',
            part31: ' تحذير من المخاطر: إن التداول في الفوركس/ العقود مقابل الفروقات والمشتقات الأخرى هو مضاربة عالية وينطوي على مستوى عالٍ من المخاطر. من الممكن أن تفقد كل رأس مالك. قد لا تكون هذه المنتجات مناسبة للجميع ويجب عليك التأكد من فهم المخاطر المرتبطة بها. ابحث عن نصيحة مستقلة إذا لزم الأمر. جازف فقط بالأموال التي يمكنك تحمل خسارتها. المعلومات الموجودة على هذا الموقع ليست موجهة للمقيمين في الولايات المتحدة وليست مخصصة للتوزيع أو الاستخدام من قبل أي شخص في أي بلد أو ولاية يكون فيها هذا التوزيع أو الاستخدام مخالفًا للقانون أو اللوائح المحلية \n  يتم تشغيل النطاق www.godocm.com بواسطة   Godo LTD،    والتي تم ترخيصها وتنظيمها من قبل لجنة الخدمات المالية  FSC في موريشيوس رقم الترخيص  GB20025812  ',
            part32: 'المعلومات الشخصية',
            part33: 'بلد الإقامة',
            part34: 'العنوان الحالي / الدائم',
            part35: 'نوع الهوية',
            part36: 'رقم الهوية',
            part37: 'تحميل الوثيقة (إثبات الهوية - الجهة الأمامية)',
            part38: 'تحميل الوثيقة (إثبات الهوية - الجهة الخلفية)',
            part39: 'تحميل الوثيقة (إثبات العنوان)',
            part40: 'تاريخ الميلاد (حسب الهوية المقدمة)',
            part41: 'العام',
            part42: 'الشهر',
            part43: 'اليوم',
            part44: 'المعلومات المالية',
            part45: 'الدخل السنوي',
            part46: 'صافي الثروة',
            part47: 'طريقة الدفع المفضلة',
            part48: 'مصدر الدخل',
            part49: 'تحميل الوثيقة (إثبات الدخل)',
            part50: 'وثائق إضافية (إن وجدت)',
            part51: 'السابق',
            part52: 'هل لديك تجربة سابقة في التداول؟',
            part53: 'نعم',
            part54: 'لا',
            part55: 'أريد أن أتعلم، وقد فهمت جميع المخاطر المرتبطة بهذه المنصة التجارية.',
            part56: 'هل سيتم التداول في هذا الحساب بواسطة أي شخص آخر غيرك؟',
            part57: 'أوافق على شروط التداول الإلكتروني.',
            part58: 'هل أنت مواطن أمريكي وتخضع للضرائب؟',
            part59: 'شخص ذو صفة سياسية (PEP)',
            part60: 'هل أنت شخص ذو صفة سياسية (PEP)',
            part61: 'إقرار:' ,
            part62: '	قبل تقديم نموذج الطلب هذا، من الضروري أن تكون قد قرأت وفهمت الشروط والأحكام التالية التي ستنظم حساب التداول الخاص بك مع  GODO LTD ("GoDo")،  والتي تشكل مع هذا النموذج "اتفاق العميل" الخاص بنا معك',
            part63: ' أعلن بموجب هذا ما يلي :',
            part64: ' أوافق على أنني اطلع على جميع المعلومات وأفهمها جيدًا :',
            part65: ' الشروط والأحكام :',
            part66: ' سياسة الشكاوى :',
            part67: ' سياسة تضارب المصالح :',
            part68: ' سياسة ملفات تعريف الارتباط :',
            part69: ' إخلاء مسؤولية بطاقة الائتمان :',
            part70: ' سياسة التنفيذ :',
            part71: ' سياسة الخصوصية :',
            part72: '	السحب والإيداع :',
            part73: ' تحذير المخاطر :',
            part74: ' أفهم المخاطر المرتبطة بالتداول بالهامش وأدرك أنه يمكن أن أتكبد خسارة كبيرة تفوق قيمة الوديعة الأولية للحساب .',
            part75: ' أؤكد صحة جميع المعلومات المقدمة، وفي حال حدوث أي تغيير، أتحمل مسؤولية إبلاغ GoDo بذلك كتابةً .',
            part76: ' أقوم بالتوقيع على هذا العقد مع شركة جودو المحدودة، وأوافق على أن حسابي التداول يخضع للوائح هيئة الخدمات المالية FSA في موريشيوس .',
            part77: 'تقديم',
            part78: 'ما هي المنتجات التي قمت بتداولها؟',
            part79: 'تردد',
            part80: 'يرجى إرفاق التوكيل الرسمي',
            part81: 'قصدير',
            part82: '  ("FSC License No GB20025812 <0>"GODO LTD</0>) هي شركة محدودة مرخصة ومسجلة بموجب قوانين موريشيوس. نحن نضمن أن حسابك سيدار بالامتثال الكامل للقواعد واللوائح المنصوص عليها من قبل لجنة الخدمات المالية (FSC).',
            part83: '"  مقدمة جودو للخدمات المالية ذ.م.م." (ترخيص هيئة الأوراق المالية والسلع رقم: 20200000168) هي شركة ذات مسؤولية محدودة مرخصة ومسجلة حسب الأصول بموجب لوائح  \n  (ترخيص  Limited GoDo هيئة الأوراق المالية والسلع رقم: 20200000168)  هي شركة ذات مسؤولية محدودة مرخصة ومسجلة حسب الأصول بموجب لوائح . نحن نعمل في شراكة حصرية مع  \n  ليس لديها إذن للاحتفاظ بها GODO (موريشيوس) لتعريف العملاء بمجموعة من الخدمات المالية. من المهم ملاحظة أن مقدمة \n',
            part84: 'لقد وقعت بموجب هذا اتفاقيتي مع GODO LTD وأوافق على أن حساب التداول الخاص بي يخضع للوائح بموجب لجنة الخدمات المالية (FSC) ، موريشيوس.',
            part85: 'لقد وقعت بموجب هذا اتفاقيتي مع شركة جودو المحدودة وأوافق على أن حساب التداول الخاص بي يخضع للوائح الصادرة عن هيئة الأوراق المالية والسلع (SCA) ، الإمارات العربية المتحدة.',
            part86: '',
            part87: '',
            part88: '',
            part89: '',
            part90: '',
          }
        }
      },
      fre: {
        translation: {
          description: {
            part1: "C'est une inscription en ligne sécurisée",
            part2: "Déverrouillez le monde des opportunités : commencez votre parcours de trading dès aujourd'hui !",
            partBack: "<0>\"GODO Capital Limited”</0> (Licence FSA No 26005) est une société à responsabilité limitée autorisée et enregistrée conformément aux lois de <1>Saint-Vincent-et-les-Grenadines.</1> Nous veillons à ce que votre compte soit géré en totale conformité avec les règles et règlements établis par l'<2>Autorité des services financiers (FSA)</2>.",
            part4: "Formulaire de demande",
            part5: "Vous êtes à un pas de réaliser vos objectifs financiers... Plongez dès maintenant !",
            part6: "Informations de base",
            part7: "Personnelles et financières",
            part8: "Informations de trading",
            part9: "Déclaration",
            part10: "Nom complet (tel que sur votre passeport ou carte d'identité nationale)",
            part11: "Adresse e-mail",
            part12: "Nationalité",
            part13: "*Veuillez entrer votre nom tel qu'il apparaît sur votre passeport ou carte d'identité nationale",
            part14: "Plateforme",
            part15: "Type de compte",
            part16: "Mot de passe",
            part17: "Confirmez le mot de passe",
            part18: "Pays de résidence",
            part19: "Numéro de téléphone",
            part20: "Le mot de passe doit",
            part21: "Au moins 1 lettre minuscule",
            part22: "1 lettre majuscule",
            part23: "Au moins 1 chiffre",
            part24: "Minimum 8 caractères",
            part25: "Maximum 12 caractères",
            part26: "1 caractère spécial ($, #, @, &)",
            part27: "Les informations fournies sont correctes et j'autorise GoDo à utiliser ces informations pour évaluer le niveau d'adéquation des services et produits fournis.",
            part28: "Suivant",
            part29: "<0>Faites preuve de prudence :</0> Si vous avez des doutes sur les risques liés au trading ou si vous n'êtes pas à l'aise avec l'effet de levier, il est conseillé de ne pas poursuivre avec le formulaire de demande, car le trading comporte un risque de perte de votre investissement initial.",
            part30: "Pour toute assistance, veuillez contacter notre équipe du service client à cs@godocm.com",
            part31: "*Avertissement sur les risques : Le trading de Forex/CFD et autres dérivés est hautement spéculatif et comporte un niveau de risque élevé. Il est possible de perdre tout votre capital. Ces produits peuvent ne pas convenir à tout le monde et vous devez vous assurer de comprendre les risques impliqués. Si nécessaire, demandez conseil à un expert. Ne spéculer qu'avec des fonds que vous pouvez vous permettre de perdre. Les informations sur ce site ne s'adressent pas aux résidents des États-Unis et ne sont pas destinées à être distribuées ou utilisées par une personne dans un pays ou une juridiction où une telle distribution ou utilisation serait contraire à la loi ou à la réglementation locale. Le domaine www.godocm.com est exploité par Godo Ltd, autorisé et réglementé par la Financial Service Commission (FSC) à Maurice (Licence No GB20025812).",
            part32: "Informations personnelles",
            part33: "Pays de résidence",
            part34: "Adresse actuelle/permanente",
            part35: "Type d'identification",
            part36: "Numéro d'identification",
            part37: "Télécharger le document (preuve d'identité - recto)",
            part38: "Télécharger le document (preuve d'identité - verso)",
            part39: "Télécharger le document (preuve d'adresse)",
            part40: "Date de naissance (selon l'identification fournie)",
            part41: "Année",
            part42: "Mois",
            part43: "Jour",
            part44: "Informations financières",
            part45: "Revenu annuel",
            part46: "Valeur nette",
            part47: "Mode de paiement préféré",
            part48: "Source de revenu",
            part49: "Télécharger le document (preuve de revenu)",
            part50: "Document complémentaire (si nécessaire)",
            part51: "Précédent",
            part52: "Avez-vous déjà fait du trading ?",
            part53: "Oui",
            part54: "Non",
            part55: "Je veux apprendre et j'ai compris tous les risques associés à cette plateforme de trading.",
            part56: "Ce compte sera-t-il utilisé par quelqu'un d'autre que vous ?",
            part57: "J'accepte les conditions de trading électronique",
            part58: "Êtes-vous citoyen américain et soumis à l'impôt ?",
            part59: "Personne politiquement exposée (PEP) :",
            part60: "Êtes-vous un PEP ?",
            part61: "Déclaration :",
            part62: "Avant de soumettre ce formulaire de demande, il est essentiel que vous ayez lu et compris les termes et conditions suivants qui régiront votre compte de trading avec GODO LTD (« GoDo »), et qui, avec ce formulaire de demande, constituent notre 'Accord Client' avec vous.",
            part63: "Je déclare par la présente ce qui suit :",
            part64: "Je confirme que j'ai accès à toutes les informations et que j'ai bien compris :",
            part65: "Termes et conditions ;",
            part66: "Politique de réclamation ;",
            part67: "Politique de gestion des conflits d'intérêts ;",
            part68: "Politique en matière de cookies ;",
            part69: "Clause de non-responsabilité sur les cartes de crédit ;",
            part70: "Politique d'exécution ;",
            part71: "Politique de confidentialité ;",
            part72: "Retrait et remboursement ;",
            part73: "Avertissement sur les risques",
            part74: "Je comprends le risque du trading sur marge et je suis conscient qu'une perte importante supérieure au dépôt initial peut être encourue",
            part75: "Je confirme que toutes les informations fournies sont correctes et en cas de modification, je suis responsable d'en informer GoDo par écrit.",
            part76: "Je signe par la présente mon accord avec GODO LTD et j'accepte que mon compte de trading soit soumis aux réglementations de la Financial Services Authority (FSA), Saint-Vincent.",
            part77: "Soumettre",
            part78: "Quels produits avez-vous tradés ?",
            part79: "Fréquence",
            part80: "Veuillez joindre une procuration",
            part81: "TIN",
            part82: '<0>"GODO LTD”</0> (Licence FSC No GB20025812) est une société à responsabilité limitée autorisée et enregistrée sous les lois de <1>Maurice</1>. Nous veillons à ce que votre compte soit géré en totale conformité avec les règles et règlements établis par la <2>Commission des services financiers (FSC)</2>.',
            part83: "<0>\"GODO Introduction Financial Services L.L.C.\"</0> (Licence SCA no : 20200000168) est une société à responsabilité limitée dûment autorisée et enregistrée sous la réglementation de l'<1>Autorité des valeurs mobilières et des marchandises (SCA) aux Émirats Arabes Unis</1>. Nous opérons en partenariat exclusif avec <2>GODO Limited (Maurice)</2> pour présenter aux clients une gamme de services financiers. Il est important de noter que GODO Introduction n'est pas autorisé à détenir des actifs ou des fonds de clients. Tous les services sont strictement fournis sur une base d'introduction.",
            part84: '',
            part85: '',
            part86: '',
            part87: '',
            part88: '',
            part89: '',
            part90: '',
          }
        }
      },
      fa: {
        translation: {
          description: {
            part1: 'این یک ثبت‌نام آنلاین امن است',
            part2: 'جهان فرصت‌ها را باز کنید: سفر تجاری خود را امروز شروع کنید!',
            partBack: '<0>"شرکت محدود GODO Capital"</0> (مجوز FSA شماره 26005) یک شرکت محدود مجاز و ثبت‌شده تحت قوانین <1>سنت وینسنت و گرنادین‌ها</1> است. ما تضمین می‌کنیم که حساب شما به طور کامل مطابق با قوانین و مقررات تعیین شده توسط <2>اداره خدمات مالی (FSA)</2> مدیریت خواهد شد.',
            part4: 'فرم درخواست',
            part5: 'تنها یک قدم تا رسیدن به اهداف مالی خود فاصله دارید... هم اکنون شروع کنید!',
            part6: 'اطلاعات پایه‌ای',
            part7: 'اطلاعات شخصی و مالی',
            part8: 'اطلاعات تجاری',
            part9: 'اعلامیه',
            part10: 'نام کامل (طبق گذرنامه یا کارت ملی شما)',
            part11: 'آدرس ایمیل',
            part12: 'ملیت',
            part13: '*لطفاً نام خود را طبق گذرنامه یا کارت ملی خود وارد کنید',
            part14: 'پلتفرم',
            part15: 'نوع حساب',
            part16: 'رمز عبور',
            part17: 'تأیید رمز عبور',
            part18: 'کشور محل اقامت',
            part19: 'شماره تلفن',
            part20: 'رمز عبور باید',
            part21: 'حداقل 1 حرف کوچک داشته باشد',
            part22: '1 حرف بزرگ (حروف بزرگ) داشته باشد',
            part23: 'حداقل 1 عدد داشته باشد',
            part24: 'حداقل 8 کاراکتر باشد',
            part25: 'حداکثر 12 کاراکتر باشد',
            part26: '1 کاراکتر خاص ($,#,@,&) داشته باشد',
            part27: 'اطلاعات ارائه شده صحیح است و من به گودو اجازه می‌دهم از این اطلاعات برای ارزیابی سطح مناسب بودن خدمات و محصولات ارائه شده استفاده کند.',
            part28: 'بعدی',
            part29: "<0>احتیاط کنید:</0> اگر در مورد خطرات معاملات اطمینان ندارید یا با اهرم راحت نیستید، بهتر است با فرم درخواست ادامه ندهید، زیرا معاملات شامل از دست دادن سرمایه شما می‌شود.",
            part30: 'برای دریافت کمک، لطفاً با تیم خدمات مشتریان ما در cs@godocm.com تماس بگیرید.',
            part31: '*هشدار ریسک: معاملات در فارکس/ قراردادهای ما به التفاوت و سایر مشتقات بسیار سوداگرانه است و ریسک بالایی دارد. ممکن است کل سرمایه خود را از دست بدهید. این محصولات ممکن است برای همه مناسب نباشد و باید مطمئن شوید که خطرات مرتبط را درک کرده‌اید. در صورت لزوم از مشاوران مستقل کمک بگیرید. فقط با سرمایه‌ای که می‌توانید از دست بدهید، سرمایه‌گذاری کنید. اطلاعات موجود در این سایت به ساکنان ایالات متحده هدایت نشده است و برای توزیع یا استفاده توسط هیچ شخصی در هیچ کشوری که چنین توزیع یا استفاده‌ای مغایر با قوانین محلی باشد، در نظر گرفته نشده است. دامنه www.godocm.com توسط گودو با مسئولیت محدود اداره می‌شود که تحت نظارت و مجوز کمیسیون خدمات مالی (FSC) در موریس است (مجوز شماره GB20025812).',
            part32: 'اطلاعات شخصی',
            part33: 'کشور محل اقامت',
            part34: 'آدرس فعلی / دائمی',
            part35: 'نوع شناسایی',
            part36: 'شماره شناسایی',
            part37: 'آپلود سند (اثبات هویت - جلو)',
            part38: 'آپلود سند (اثبات هویت - پشت)',
            part39: 'آپلود سند (اثبات آدرس)',
            part40: 'تاریخ تولد (طبق شناسنامه ارائه شده)',
            part41: 'سال',
            part42: 'ماه',
            part43: 'روز',
            part44: 'اطلاعات مالی',
            part45: 'درآمد سالانه',
            part46: 'ارزش خالص',
            part47: 'روش پرداخت مورد ترجیح',
            part48: 'منبع درآمد',
            part49: 'آپلود سند (اثبات درآمد)',
            part50: 'سند تکمیلی (در صورت وجود)',
            part51: 'قبلی',
            part52: 'آیا تجربه تجارت دارید؟',
            part53: 'بله',
            part54: 'خیر',
            part55: 'من می‌خواهم یاد بگیرم و تمام خطرات مرتبط با این پلتفرم معاملاتی را درک کرده‌ام.',
            part56: 'آیا این حساب توسط کسی غیر از شما معامله خواهد شد؟',
            part57: 'شرایط تجارت الکترونیک را می‌پذیرم',
            part58: 'آیا شهروند ایالات متحده هستید و مشمول مالیات هستید؟',
            part59: 'شخص سیاسی افشا شده (PEP):',
            part60: 'آیا شما یک PEP هستید؟',
            part61: 'اعلامیه:',
            part62: 'قبل از ارسال این فرم درخواست، مهم است که شرایط و ضوابط زیر را که حساب معاملاتی شما با گودو LTD ("گودو") حاکم است، مطالعه کرده و درک کرده باشید و که به همراه این فرم درخواست، توافق‌نامه مشتری ما با شما را تشکیل می‌دهد.',
            part63: 'من در اینجا اعلام می‌کنم که:',
            part64: 'من تأیید می‌کنم که به همه اطلاعات دسترسی داشته‌ام و به‌طور کامل درک کرده‌ام:',
            part65: 'شرایط و ضوابط;',
            part66: 'سیاست شکایات;',
            part67: 'سیاست تضاد منافع;',
            part68: 'سیاست کوکی‌ها;',
            part69: 'سلب مسئولیت کارت اعتباری;',
            part70: 'سیاست اجرا;',
            part71: 'سیاست حفظ حریم خصوصی;',
            part72: 'برداشت و بازپرداخت;',
            part73: 'هشدار ریسک',
            part74: 'من خطر تجارت حاشیه‌ای را درک می‌کنم و می‌دانم که ضرر قابل توجهی بیش از سپرده اولیه می‌تواند رخ دهد',
            part75: 'من تأیید می‌کنم که تمام اطلاعات ارائه شده صحیح است و در صورت هرگونه تغییر، مسئولیت دارم که به صورت کتبی به گودو اطلاع دهم.',
            part76: 'من در اینجا توافق‌نامه خود را با گودو LTD امضا کرده‌ام و قبول دارم که حساب معاملاتی من تحت مقررات اداره خدمات مالی (FSA)، سنت وینسنت قرار دارد.',
            part77: 'ارسال',
            part78: 'در چه محصولاتی معامله کرده‌اید؟',
            part79: 'تکرار',
            part80: 'لطفاً وکالت‌نامه را پیوست کنید',
            part81: 'TIN',
            part82: '<0>"شرکت محدود GODO LTD"</0> (مجوز FSC شماره GB20025812) یک شرکت محدود مجاز و ثبت‌شده تحت قوانین <1>موریس</1> است. ما تضمین می‌کنیم که حساب شما به طور کامل مطابق با قوانین و مقررات تعیین شده توسط <2>کمیسیون خدمات مالی (FSC)</2> مدیریت خواهد شد.',
            part83: '<0>"خدمات مالی معرفی GODO L.L.C."</0> (مجوز SCA شماره: 20200000168) یک شرکت با مسئولیت محدود مجاز و ثبت‌شده تحت مقررات <1>سازمان کالاهای اوراق بهادار (SCA) در امارات متحده عربی</1> است. ما در یک شراکت انحصاری با <2>گودو محدود (موریس)</2> کار می‌کنیم تا مشتریان را به یک طیف از خدمات مالی معرفی کنیم. مهم است بدانید که GODO Introduction مجاز به نگه‌داشتن دارایی‌ها یا وجوه مشتریان نیست. تمامی خدمات به طور انحصاری بر اساس معرفی ارائه می‌شوند.',
            part84: 'من در اینجا توافق‌نامه خود را با گودو LTD امضا کرده‌ام و قبول دارم که حساب معاملاتی من تحت مقررات کمیسیون خدمات مالی (FSC)، موریس قرار دارد.',
            part85: 'من در اینجا توافق‌نامه خود را با گودو LTD امضا کرده‌ام و قبول دارم که حساب معاملاتی من تحت مقررات سازمان کالاهای اوراق بهادار (SCA)، امارات متحده عربی قرار دارد.',
            part86: '',
            part87: '',
            part88: '',
            part89: '',
            part90: '',
          }
        }
      },
      
    }
  });

export default i18n; 